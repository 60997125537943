import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import { SessionService, VehiclesService } from '../../services';
import { Sidebar } from '../../components';
import TableSearch from '../../components/TableSearch';

const VehiclesList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pageCountShow, setPageCountShow] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCurrent, setPageCurrent] = useState(0);
  const pageLimit = 100;

  useEffect(() => {
    const getData = async () => {
      const data = await VehiclesService.getAllVehicles(pageCurrent, pageLimit, sortBy, sortOrder, search);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setVehicles(() => data.results);
        setPageCount(() => data.count);
        setPageCountShow(() => Math.ceil(data.count / pageLimit));
      }
    };

    getData();
  }, [pageCurrent, sortBy, sortOrder, search]);

  const handleSearch = async text => {
    setSearch(() => text);

    const data = await VehiclesService.getAllVehicles(pageCurrent, pageLimit, sortBy, sortOrder, text);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setVehicles(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
    }
  };

  const handlePageChange = async event => {
    const data = await VehiclesService.getAllVehicles(event.selected, pageLimit, sortBy, sortOrder, search);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setVehicles(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
      setPageCurrent(event.selected);
    }
  };

  const showAvatar = avatar => {
    return avatar ? avatar : '/avatar.jpg';
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                  />
                </svg>
                <h1 className="text-xl font-semibold">Vehicles {pageCount > 0 && <span>({pageCount})</span>}</h1>
              </div>

              <TableSearch onChange={handleSearch} />

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap">Brand, Model &amp; Variant</th>
                    <th className="p-2 text-left whitespace-nowrap">Driver</th>
                    <th className="p-2 text-center whitespace-nowrap">Drivetrain</th>
                    <th className="p-2 text-center whitespace-nowrap">Year</th>
                    <th className="p-2 text-center whitespace-nowrap">Transmission</th>
                    <th className="p-2 text-center whitespace-nowrap">Power</th>
                    <th className="p-2 text-center whitespace-nowrap">Weight</th>
                    <th className="p-2 text-center whitespace-nowrap">Is default</th>
                    <th className="p-2 text-center whitespace-nowrap">Mods</th>
                    <th className="p-2 text-center whitespace-nowrap">Created</th>
                    <th className="p-2 text-center whitespace-nowrap">Updated</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {vehicles.length > 0 ? (
                    vehicles.map(vehicle => (
                      <tr key={vehicle._id}>
                        <td className="p-2 text-left whitespace-nowrap">
                          <Link to={vehicle._id}>
                            {vehicle.brand?.name} {vehicle.model?.name} {vehicle.variant?.name}
                          </Link>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 mr-2 sm:mr-3">
                              <Link to={`/users/${vehicle.user?._id}`}>
                                <img
                                  className={`inline-block rounded-full ring-2 ring-gray-500 overflow-clip`}
                                  src={showAvatar(vehicle.user?.avatar)}
                                  width="32"
                                  height="32"
                                  alt={vehicle.user?.name}
                                />
                              </Link>
                            </div>
                            {vehicle.user?.name ? (
                              <div className="font-medium text-gray-800">
                                <Link to={`/users/${vehicle.user?._id}`}>{vehicle.user?.name}</Link>
                              </div>
                            ) : (
                              '/'
                            )}
                          </div>
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{vehicle.drivetrain}</td>
                        <td className="p-2 text-center whitespace-nowrap">{vehicle.year}</td>
                        <td className="p-2 text-center whitespace-nowrap">{vehicle.transmission}</td>
                        <td className="p-2 text-center whitespace-nowrap">{vehicle.power}</td>
                        <td className="p-2 text-center whitespace-nowrap">{vehicle.weight}</td>
                        <td className="p-2 text-center whitespace-nowrap">
                          <span className={`${vehicle.isDefault && 'text-green-600'}`}>{vehicle.isDefault.toString()}</span>
                        </td>
                        <td className="p-2 text-center max-w-40">{vehicle.mods}</td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(vehicle.createdAt), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-right whitespace-nowrap">{format(new Date(vehicle.updatedAt), 'dd/MM/yyyy')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={13}>
                        No vehicles in database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageChange}
                pageRangeDisplayed={pageCount}
                pageCount={pageCountShow}
                previousLabel="< prev"
                nextLabel="next >"
                className="flex items-center my-8"
                pageClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold w-8 h-8 hover:bg-gray-200"
                pageLinkClassName="no-underline w-8 h-8 px-2 py-2 flex justify-center items-center"
                previousClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                previousLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                nextClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                nextLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                activeClassName="bg-black"
                activeLinkClassName="text-white"
                disabledClassName="bg-gray-50 hover:bg-gray-50"
                disabledLinkClassName="text-gray-400 hover:text-gray-400"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VehiclesList;
